exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-acupression-tsx": () => import("./../../../src/pages/acupression.tsx" /* webpackChunkName: "component---src-pages-acupression-tsx" */),
  "component---src-pages-contact-tarif-tsx": () => import("./../../../src/pages/contact-tarif.tsx" /* webpackChunkName: "component---src-pages-contact-tarif-tsx" */),
  "component---src-pages-fasciatherapie-tsx": () => import("./../../../src/pages/fasciatherapie.tsx" /* webpackChunkName: "component---src-pages-fasciatherapie-tsx" */),
  "component---src-pages-hypnose-tsx": () => import("./../../../src/pages/hypnose.tsx" /* webpackChunkName: "component---src-pages-hypnose-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-magnetisme-tsx": () => import("./../../../src/pages/magnetisme.tsx" /* webpackChunkName: "component---src-pages-magnetisme-tsx" */),
  "component---src-pages-memoire-cellulaire-tsx": () => import("./../../../src/pages/memoire-cellulaire.tsx" /* webpackChunkName: "component---src-pages-memoire-cellulaire-tsx" */),
  "component---src-pages-pathologies-tsx": () => import("./../../../src/pages/pathologies.tsx" /* webpackChunkName: "component---src-pages-pathologies-tsx" */),
  "component---src-pages-presse-tsx": () => import("./../../../src/pages/presse.tsx" /* webpackChunkName: "component---src-pages-presse-tsx" */),
  "component---src-pages-reiki-tsx": () => import("./../../../src/pages/reiki.tsx" /* webpackChunkName: "component---src-pages-reiki-tsx" */)
}

